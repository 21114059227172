<template>
  <div class="retransmissionDlog">
    <div class="fixed">
      <div class="back_box">
        <img src="@/assets/img/icon/back.png" alt="" @click.stop="close" />
        <div class="title">转发给</div>

        <div class="btn" @click="aetransmissioBtn">转发</div>
      </div>
    </div>
    <div class="retransmission_msg_box">
      <van-list
        @load="onLoad"
        finished-text="没有更多了"
        :finished="isSyncCompleted"
        v-model:loading="loading"
      >
        <van-checkbox-group v-model="checkedResult">
          <van-checkbox
            v-for="item in conversationList"
            :key="item.conversationID"
            :name="item.conversationID"
            class="_item"
            @click="selThis(item)"
          >
            <div class="avart">
              <img
                :src="filesCdnDomain + props.item.groupProfile.avatar"
                v-if="item.type == 'GROUP' && item.groupProfile.avatar"
              />
              <img
                :src="filesCdnDomain + item.userProfile.avatar"
                alt=""
                v-else-if="item.type == 'C2C' && item.userProfile.avatar"
              />
              <img src="@/assets/img/icon/avart.png" alt=" " v-else />
            </div>
            <div class="msg_name">
              <div class="name" v-if="item.groupProfile && item.type == 'GROUP'">
                {{ item.groupProfile.name }}
              </div>
              <div class="name" v-if="item.userProfile && item.type == 'C2C'">
                {{ item.userProfile.nick }}
              </div>
            </div>
          </van-checkbox>
        </van-checkbox-group>
      </van-list>
    </div>
  </div>
</template>
<script setup>
import MsgItem from "./components/msgItem.vue";
import { chat } from "@/untils/tim";
import TencentCloudChat from "@tencentcloud/chat";
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
const isSyncCompleted = ref(false); //会话列表是否拉完
const loading = ref(true);
const conversationList = ref([]); //会话列表

const checkedResult = ref([]); //已选数组
const selList = ref([]);
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
function close() {
  store.commit("changeRetransmissionDlog", false);
}
function _getConversationList() {
  //拉取会话列表
  chat()
    .getConversationList()
    .then((imResponse) => {
      let arr = imResponse.data.conversationList.filter((item) => item.type != "@TIM#SYSTEM");
      let arr1 = arr.filter(
        (item) =>
          item.conversationID.indexOf("GUEST_pc_") < 0 &&
          item.conversationID.indexOf("GUEST_h5_") < 0 &&
          item.conversationID.indexOf("systemadmin") < 0
      );
      let arr2 = arr1.filter(
        (item) =>
          (item.type == "GROUP" && item.groupProfile.type != "AVChatRoom") ||
          (item.type == "C2C" && item.userProfile.userID != "admin")
      );
      conversationList.value = arr2;
      isSyncCompleted.value = imResponse.data.isSyncCompleted;
      console.log(conversationList.value);
    })
    .catch((err) => {});
}
function onLoad() {
  if (!isSyncCompleted.value) {
    _getConversationList();
    loading.value = false;
  }
}
function selThis(item) {
  let arr = selList.value;
  let index = arr.findIndex((child) => child.conversationID == item.conversationID);
  if (index > -1) {
    arr.splice(index, 1);
  } else {
    arr.push(item);
  }
  selList.value = arr;
}

function aetransmissioBtn() {
  if (selList.value.length == 0) {
    showFailToast("请先选择联系人");

    return;
  }
  selList.value.forEach((child) => {
    let message;
    if (child.type == "GROUP") {
      message = chat().createForwardMessage({
        to: child.groupProfile.groupID,
        conversationType: TencentCloudChat.TYPES.CONV_GROUP,
        payload: store.state.imJs.retransmissionMsg,
      });

      sendMessage(message);
      return;
    }

    if (child.type == "C2C") {
      message = chat().createForwardMessage({
        to: child.userProfile.userID,
        conversationType: TencentCloudChat.TYPES.CONV_C2C,
        payload: store.state.imJs.retransmissionMsg,
      });
      sendMessage(message);
      return;
    }
  });
  close();
}
function sendMessage(message) {
  //发送消息
  chat()
    .sendMessage(message)
    .then((res) => {})
    .catch(() => {});
}
onMounted(() => {
  onLoad();
});
</script>
<style scoped>
.retransmissionDlog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
}
.btn {
  color: #d8ad6d;
  text-align: center;
  font-weight: 500;
  font-size: 4.26vw;
}
.retransmission_msg_box {
  flex: 1;
}
._item {
  display: flex;
  align-items: center;
  padding: 3vw;
}
.avart {
  width: 14.3vw;
  height: 12.8vw;
  margin-right: 2.66vw;
  margin-left: 2.66vw;
  position: relative;
}
.avart img {
  width: 12.8vw;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
._item /deep/ .van-checkbox__label {
  display: flex;
  align-items: center;
}
</style>
