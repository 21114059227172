<template>
  <div class="editNickName">
    <div class="back_box">
      <img src="@/assets/img/icon/back.png" alt="" @click.stop="close" />
      <div class="title">修改备注</div>
      <div class="save" @click="saveHandle" :class="active ? 'active' : ''">保存</div>
    </div>
    <div class="form">
      <van-cell-group inset>
        <van-field
          @update:model-value="change"
          v-model="remarkName"
          autosize
          maxlength="12"
          placeholder="请输入备注名称"
          show-word-limit
        />
      </van-cell-group>
    </div>
  </div>
</template>
<script setup name="PersonRemark">
import TencentCloudChat from "@tencentcloud/chat";
import { showFailToast, showSuccessToast } from "vant";
import { chat } from "@/untils/tim";
import { nextTick, ref, watch, watchEffect } from "vue";
const emit = defineEmits(["close"]);
const active = ref(false);
const remarkName = ref("");
const props = defineProps({
  name: {
    type: String,
    default: 0,
  },
  userID: {
    type: String,
    default: 0,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
function close() {
  emit("close", remarkName.value );
  remarkName.value = "";
}
function change() {
  active.value = true;
}
function saveHandle() {
  let lock = false;

  // chat()
  //   .checkFriend({
  //     userIDList: [props.userID],
  //     type: TencentCloudChat.TYPES.SNS_CHECK_TYPE_SINGLE,
  //   })
  //   .then((res) => {
  //     const { successUserIDList, failureUserIDList } = res.data;

  //     successUserIDList.forEach((element) => {
  //       if (element.userID == props.userID && element.relation != "CheckResult_Type_NoRelation") {
  //         lock = true;
  //       }
  //     });
  //   });

  chat()
    .updateFriend({
      userID: props.userID,
      remark: remarkName.value,
    })
    .then((res) => {
      showSuccessToast("修改成功");
      close();
    })
    .catch((err) => {
      showFailToast("修改失败");
    });
}
watchEffect(() => {
  if (props.visible) {
    nextTick(() => {
      remarkName.value = props.name;
    });
  }
});
</script>
<style scoped>
.editNickName {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.form {
  margin: 10px 14px;
}
</style>
<style scoped>
.editNickName .van-field {
  border-radius: 8px;
  background: #fafafa;
}
.editNickName /deep/ .van-field__word-num {
  color: #d8ad6d;
}
</style>
